<template>
  <div class="page-index">
    <hero-canvas 
      lineTop="TAKE PRIDE IN"
      lineBottom="YOUR AIRCRAFT"
      url-video="/vid/loop.mov">
    </hero-canvas>

    <!-- intro -->
    <section class="landingpageintro wrap grid32">
      <!-- I'm using long and unique names for classes so I can easily search for and replace them later when I find the components may be reusable -->
      <div class="landingpageintro__left">
        <h1 class="heading--body">
          Griggs Aircraft is a refinishing, fabrication, and maintenance shop, and the proud owner of both the legendary Wolf line of aircraft and the O&N Silver Eagle conversion kits
        </h1>
      </div>
      <div class="landingpageintro__right">
        <router-link to="">
          <button class="cta cta-body">Contact</button>
        </router-link>
      </div>
    </section>
    <!-- end intro -->

    <hr/>

    <!-- services -->
    <section class="landingpageservices wrap">
      <h2 class="heading--jumbo">Our services</h2>
      <div class="landingpageservices__services grid-blurb">
        <services-blurb
          v-for="(i, j) in servicesBlurbs"
          :key="j"
          :heading="i.heading"
          :body="i.body"
          :url="i.url">
        </services-blurb>
      </div>
    </section>
    <!-- end services -->

    <hr/>

    <gallery-parallax></gallery-parallax>


    <!-- Wolf callout -->
      <callout
        :superheading="wolfSuperHeading"
        :heading="wolfHeading"
        justify="right">
      </callout> 
      <div class="grid-blurb wrap">
        <services-blurb
           v-for="(i, j) in wolfBlurbs"
           :key="j"
           :heading="i.heading"
           :body="i.body"
           :url="i.url">
        </services-blurb>
      </div>
    <!-- Wolf callout -->
    <flag
      class="flag-class"
      text="LEARN MORE"
      font-size="12"
      :amplitude="10"
      :width="140"
      :height="32"
      :inset="0.14"
      color="white"
      hoverFill="#ef2b38">
    </flag>
    <hr>
  </div>
</template>

<script>
import HeroCanvas from "@/component/hero-canvas/Index.vue";
import ServicesBlurb from "@/component/services-blurb/Index.vue";
import Callout from "@/component/callout/Index.vue";
import Flag from "@/component/graphic/flag/Index.vue";

import GalleryParallax from "@/component/gallery-parallax/Index.vue";

import servicesBlurbs from "@/../private/services-blurbs.js";
import wolfBlurbs from "@/../private/wolf-callout-blurbs.js";


const wolfSuperHeading = "Planes, kits, S1, S1-11, & S2 mods, & custom wings";
const wolfHeading = "Steve Wolf/WolfPitts Performance at Griggs aircraft";

export default {
  name: "PageIndex",
  components: {
    HeroCanvas,
    ServicesBlurb,
    Callout,
    Flag,
    GalleryParallax
  },
  data () {
    return {
      servicesBlurbs,
      wolfBlurbs,
      wolfSuperHeading,
      wolfHeading
    }
  }
};
</script>

<style lang="sass">
@import "~SASS/mixin";

.grid16
  @include grid16

.grid32
  @include grid32

.landingpageintro
  padding: 10rem 0

.landingpageservices
  padding: 0 0 10rem 0

.landingpageintro__left
  @include span(1, end)
  @media screen and (min-width: 600px)
    @include span(1, 16)

.landingpageintro__right
  @include span(28, end)
  button
    width: 100%

.grid-blurb
  display: grid
  grid-template-columns 1fr
  grid-row-gap: 3rem
  grid-column-gap: 7.8%
  @media screen and (min-width: 550px)
    grid-template-columns: repeat(2, 46.18%)
  @media screen and (min-width: 800px)
    grid-template-columns: repeat(3, 28.125%)

.landingpageservices__services
  margin-top: 10rem
</style>

<template>
  <div class="icon-scroll">
    <div class="icon-scroll__mouse">
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        height="34">
        <rect
          x="1.5"
          y="1.5"
          width="17"
          height="32"
          rx="45%"
          :stroke="color"
          stroke-width="1.5"
          fill="transparent"
          />
          <rect
            class="icon-scroll__wheel"
            fill="white"
            />
      </svg>
    </div>
      <div class='icon-scroll__text'>SCROLL</div>
  </div>
</template>

<script>

export default {
  name: "IconScroll",
  props: {
    width: {
      type: String,
      required: false,
      default: "19"
    },
    color: {
      type: String,
      required: false,
      default: "#ffffff"
    }
  }
}
</script>

<style lang="sass">

html
  --y-val-start: 8
  --y-val-end: 16
  --height-start: 6
  --height-end: 12

@keyframes scroll-animation
  0%
    y: var(--y-val-start)
    height: var(--height-start)
  5%
    y: var(--y-val-start)
    height: var(--height-end)
  10%
    y: var(--y-val-end)
    height: var(--height-start)
  15%
    y: var(--y-val-start)
    height: var(--height-start)
  100%
    y: var(--y-val-start)
    height: var(--height-start)

.icon-scroll
  display: flex
  flex-direction: column

.icon-scroll__mouse
  align-self: center

.icon-scroll__text
  font-family: GtFlexaRegular, sans-serif
  margin-top: 0.8rem
  font-size: 0.7rem
  align-self: center

.icon-scroll__wheel
  x: 7
  width: 6
  rx: 3
  animation: scroll-animation 4s infinite ease-in-out
</style>

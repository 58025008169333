var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      ref: "scribble1svg",
      staticStyle: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "1.5"
      },
      attrs: {
        width: _vm.width,
        viewBox: "0 0 1574 196",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "xml:space": "preserve",
        "xmlns:serif": "http://www.serif.com/"
      }
    },
    [
      _c("g", { attrs: { transform: "matrix(1,0,0,1,-417.039,-2902.42)" } }, [
        _c("g", { attrs: { transform: "matrix(1,0,0,1,0,-6596.11)" } }, [
          _c("path", {
            class: _vm.scribbleClass,
            staticStyle: {
              fill: "none",
              stroke: "url(#_Linear1)",
              "stroke-width": "2.5px"
            },
            attrs: {
              d:
                "M418.289,9606.04C418.289,9606.04 1026.28,9401.15 1738.88,9560.4C2451.49,9719.65 1458.99,9537.43 1020.26,9606.04C777.745,9643.97 1294.82,9587.93 1456.35,9692.44"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "_Linear1",
                x1: "0",
                y1: "0",
                x2: "1",
                y2: "0",
                gradientUnits: "userSpaceOnUse",
                gradientTransform: "matrix(1686.15,0,0,133.777,310.453,16570.8)"
              }
            },
            [
              _c("stop", {
                staticStyle: {
                  "stop-color": "rgb(255,22,25)",
                  "stop-opacity": "1"
                },
                attrs: { offset: "0" }
              }),
              _c("stop", {
                staticStyle: {
                  "stop-color": "rgb(255,22,25)",
                  "stop-opacity": "1"
                },
                attrs: { offset: "0.5" }
              }),
              _c("stop", {
                staticStyle: {
                  "stop-color": "rgb(236,111,31)",
                  "stop-opacity": "1"
                },
                attrs: { offset: "1" }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
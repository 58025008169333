<template>
  <div :ref="idName" class="wrap callout">

    <video
      ref="thetestelement1" 
      src="/vid/wolf/bennet-spin.mp4"
      class="callouttestelement"
      loop
      autoplay
      muted>
    </video>
    <video 
      ref="thetestelement2"
      src="/vid/wolf/tucker-blue-angels.mp4"
      class="callouttestelement2"
      loop
      autoplay
      muted>
    </video>
    <video 
      ref="thetestelement3"
      src="/vid/wolf/bennet-inside-pitts.mp4"
      class="callouttestelement3"
      loop
      autoplay
      muted>
    </video>
    <aside
      class="heading--superheading"
      :style="bodyStyle">
      {{ superheading }}
    </aside>
    <h1 
      ref="randomHeading"
      class="heading--jumbo random-heading-style"
      :style="headingStyle">
      {{ heading }}
    </h1>
    <scribble-1 class="scribble" width="80%"></scribble-1>
  </div>
</template>

<script>
import Scribble1 from "@/component/graphic/scribble/Scribble1.vue";
import Flag from "@/component/graphic/flag/Index.vue";
import { parallax } from "@/util/parallax.js";


export default {
  name: "",
  props: {
    idName: {
      type: String,
      required: false,
      default: "randomName"
    },
    superheading: {
      type: String,
      required: false,
      default: "Superheading"
    },
    heading: {
      type: String,
      required: false,
      default: "This is where the heading goes"
    },
    justify: {
      type: String,
      required: false,
      default: "left"
    }
  },
  methods: {
    move () {
      // ...
    }
  },
  components: {
    Scribble1,
    Flag
  },
  computed: {
    textAlign () {
      return this.justify === "right" ? "right" : "left";
    },
    headingStyle () {
      return {
        "text-align": this.textAlign
      }
    },
    bodyStyle () {
      return {
        "text-align": this.textAlign
      }
    }
  },
  mounted () {
    parallax(window, this.$refs.thetestelement2, 1.15);
    parallax(window, this.$refs.thetestelement1, 1.4);
    parallax(window, this.$refs.thetestelement3, 1.5);
  }
}
</script>

<style lang="sass">

@mixin content
  position: absolute
  width:  200px
  height: 120px
  background: #444
  object-fit: cover
  &:hover
    transform: scale(1.6) !important

.callout
  & > *
    position: relative

.scribble
  position: relative
  display: block
  left: 50%
  transform: translateX(-50%)
  margin-bottom: 10rem

.callouttestelement
  @include content
  left: 75%
  margin-top: 620px

.callouttestelement2
  @include content
  left: 43.75%
  margin-top: 50px

.callouttestelement3
  @include content
  left: 12.5%
  margin-top: 500px

</style>

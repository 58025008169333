<template>
  <svg 
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(1,0,0,1,-742.902,-3021.1)">
        <g id="icon-link-out" transform="matrix(1.01668,0,0,1,56.8602,-870.141)">
         <path d="M685.592,3896.23L674.788,3896.23L674.788,3891.24L694.108,3891.24L694.108,3910.57L689.12,3910.57L689.12,3899.76L678.316,3910.57L674.788,3910.57L674.788,3907.04L685.592,3896.23Z" style="fill:rgb(239,43,56);"/>
       </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: "IconArrowOut",
  props: {
    color: {
      type: String,
      required: false,
      default: "#ef2b38"
    }
  }
};
</script>

<style lang="sass">
</style>

// Velocity 1 does nothing
// Play around with values

const createOpts = ancestor => {
  return {
    root: ancestor === window ? null : ancestor,
    rootMargin: "0px 0px",
    threshold: 0
  }
}

const handleScroll = (target, velocity) => {
  const dft = window.scrollY + target.getBoundingClientRect().y;
  const bcr = target.getBoundingClientRect().y;
  return e => {
    const windowBottom = window.scrollY + window.innerHeight;
    const differential = dft - windowBottom;
    target.style.transform = `translateY(${differential*velocity-differential}px)`;
  };
};


const handlerHelper = (ancestor, target, velocity) => {
  const listener = handleScroll(target, velocity);
  return ents => {
    ents.forEach((ent) => {
      if (ent.isIntersecting) {
        ancestor.addEventListener("scroll", listener, false);
      } else {
        ancestor.removeEventListener("scroll", listener, false);
      }
    });
  }
};

export const parallax = (ancestor, target, velocity) => {
  const handler = handlerHelper(ancestor, target, velocity);
  const observer = new IntersectionObserver(handler, createOpts(ancestor));
  observer.observe(target);
};

import { factory } from "./blurb-utils";


const painting = factory(
  "Painting",
  "We use the highest-quality aerospace paints and partner with experienced scheme designers to help you achieve your vision. We also specialize in custom graphics, paint rejuvenation, and refinishing services.",
  "/refinishing"
);

const fabrication = factory(
  "Fabrication",
  "Griggs Aircraft offers a range of auxiliary fuel tank STCs for Cessna, Grumman, and Piper pilots, as well as fabrication services for Wolf-modded Pitts Aircraft in order to turn them airshow-ready.",
  "/fabrication"
);

const upgrades = factory(
  "Upgrades",
  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney.",
  "/upgrades"
);

const maintenance = factory(
  "Maintenance",
  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney.",
  "/maintenance"
);

const wolf = factory(
  "Wolf products",
  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney.",
  "/wolf"
);

const on = factory(
  "O&N/Silver Eagle",
  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney.",
  "/on"
);


export default [
  painting,
  fabrication,
  upgrades,
  maintenance,
  wolf,
  on
];

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "theherocontainer", staticClass: "theherocontainerclass" },
    [
      _c("global-header"),
      _vm._v(" "),
      _c("video", {
        ref: "thecoolvideo",
        staticClass: "thecoolvideoclass",
        attrs: { loop: "", muted: "", autoplay: "", src: _vm.urlVideo },
        domProps: { muted: true }
      }),
      _vm._v(" "),
      _c("canvas", { ref: "theherocanvas", staticClass: "theherocanvasclass" }),
      _vm._v(" "),
      _c("div", { staticClass: "theherocontainer__middlearea" }, [
        _c("div", { ref: "theFirstLine" }, [_vm._v("Take pride in")]),
        _vm._v(" "),
        _c("div", { ref: "theSecondLine" }, [_vm._v("your aircraft")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "theherocontainer__bottomarea wrap" }, [
        _c("span", { staticClass: "theherocontainer__bottomarea__text" }, [
          _vm._v(
            "\n      Griggs Aircraft is a refinishing, fabrication, and maintenance shop, and the proud owner of both the legendary Wolf line of aircraft modifications and the O&N Silver Eagle conversions.\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { on: { click: _vm.scrollDown } },
          [_c("icon-scroll", { staticClass: "icon-scroll" })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-scroll" }, [
    _c("div", { staticClass: "icon-scroll__mouse" }, [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: _vm.width,
            height: "34"
          }
        },
        [
          _c("rect", {
            attrs: {
              x: "1.5",
              y: "1.5",
              width: "17",
              height: "32",
              rx: "45%",
              stroke: _vm.color,
              "stroke-width": "1.5",
              fill: "transparent"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            staticClass: "icon-scroll__wheel",
            attrs: { fill: "white" }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "icon-scroll__text" }, [_vm._v("SCROLL")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
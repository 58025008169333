<template>
  <section 
    ref="theherocontainer"
    class="theherocontainerclass">
    <global-header></global-header>
    <video
      ref="thecoolvideo"
      class="thecoolvideoclass"
      loop
      muted
      autoplay
      :src="urlVideo">
    </video>
    <canvas
      ref="theherocanvas"
      class="theherocanvasclass">
    </canvas>
    <div class="theherocontainer__middlearea">
      <div ref="theFirstLine">Take pride in</div>
      <div ref="theSecondLine">your aircraft</div>
    </div>
    <div class="theherocontainer__bottomarea wrap">
      <span class="theherocontainer__bottomarea__text">
        Griggs Aircraft is a refinishing, fabrication, and maintenance shop, and the proud owner of both the legendary Wolf line of aircraft modifications and the O&amp;N Silver Eagle conversions.
      </span>
      <div @click="scrollDown">
      <icon-scroll
        class="icon-scroll">
      </icon-scroll>
      </div>
    </div>
  </section>
</template>

<script>
import { getPixelRatio, fitCanvasTo, getCoverValues, drawCover, drawCoverZoom } from "@/util/canvas.js";
import IconScroll from "@/component/icon/scroll/Index.vue";
import GlobalHeader from "@/component/global/header/Index.vue";


export default {
  name: "HeroCanvas",
  props: {
    urlVideo: {
      type: String,
      required: true
    },
    lineTop: {
      type: String,
      required: true
    },
    lineBottom: {
      type: String,
      required: true
    }
  },
  components: {
    GlobalHeader,
    IconScroll
  },
  methods: {
    scrollDown () {
      window.scrollTo(0, window.innerHeight);
    },
    copyVideoToCanvas (canvas, ctx, video, pixelRatio, text1, text2) {
      const coverValues = getCoverValues(canvas, video, pixelRatio);
      drawCoverZoom(canvas, ...coverValues, 1.5);
      requestAnimationFrame(() => {
        this.copyVideoToCanvas(canvas, ctx, video, pixelRatio, text1, text2);
      });
    },
    setText (ctx, pixelRatio, bcr1, bcr2) {
      const fontSize = window.getComputedStyle(this.$refs.theFirstLine, null).getPropertyValue("font-size");
      const fontFamily = window.getComputedStyle(this.$refs.theFirstLine, null).getPropertyValue("font-family");
      ctx.textBaseline = "top";
      ctx.font = `${fontSize} ${fontFamily}`;
      ctx.beginPath();
			ctx.fillText(this.lineTop, bcr1.x, bcr1.y);
			ctx.fillText(this.lineBottom, bcr2.x, bcr2.y);
			ctx.fill();
    },
    resetCanvas (ctx, pixelRatio, bcr1, bcr2) {
      fitCanvasTo(this.$refs.theherocanvas, this.$refs.theherocontainer, pixelRatio)
      this.setText(ctx, pixelRatio, bcr1, bcr2);
    }
  },
  mounted () {
    const pixelRatio = getPixelRatio();
    const canvas = this.$refs.theherocanvas;
    const ctx = this.$refs.theherocanvas.getContext("2d");
    const bcrTopText = this.$refs.theFirstLine.getBoundingClientRect();
    const bcrBottomText = this.$refs.theSecondLine.getBoundingClientRect();


    this.$refs.thecoolvideo.addEventListener("play", () => {
      this.resetCanvas(ctx, pixelRatio, bcrTopText, bcrBottomText);
			ctx.globalCompositeOperation = "source-atop";
      this.copyVideoToCanvas(canvas, ctx, this.$refs.thecoolvideo, pixelRatio, bcrTopText, bcrBottomText);
    });

    addEventListener("resize", () => {
      const bcrTopText = this.$refs.theFirstLine.getBoundingClientRect();
      const bcrBottomText = this.$refs.theSecondLine.getBoundingClientRect();
      this.resetCanvas(ctx, pixelRatio, bcrTopText, bcrBottomText);
			ctx.globalCompositeOperation = "source-atop";
    })
  }
};
</script>

<style lang="sass">
@import "~SASS/mixin";

.theherocanvasclass
  position: absolute
  top: 0
  left: 0

.theherocontainer__middlearea
  @include heading
  color: transparent
  & > div
    display: inline-block
  font-size: 11vw
  line-height: .88em
  position: relative
  text-align: center

.theherocontainer__bottomarea
  display: flex
  position: relative
  color: white
  justify-content: space-between
  align-items: flex-end
  padding-bottom: 2rem
  &__text
    max-width: 87.5%

.theherocontainerclass
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  height: 100%
  position: relative

.thecoolvideoclass
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  object-fit: cover

.icon-scroll
  cursor: pointer
</style>

import { factory } from "./blurb-utils.js";


const wolfPro360 = factory(
  "WolfPro 360",
  "The latest, most advanced single-seat 200HP biplane on the market. This aircraft incorporates Wolf Aircraft’s high performance S1 Wing Kit and a completely redesigned fuselage and tail group.",
  "/wolf/wolfpro_360"
);

const wolfPitts = factory(
  "WolfPitts",
  "A highly refined Pitts S-1 S with aerodynamically boosted ailerons, squared wing tips, a reshaped tail, flush canopy rails, a carbon fiber annular inlet cowl and rod type landing gear.",
  "/wolf/wolfpitts"
);

const kitsPartsAndMore = factory(
  "Kits, parts, & more",
  "Custom wings, tail groups, cowlings, rod gear conversion kits, welded components, and more for the Pitts S-1, S-1-11, and S-2 variants in order to take your aircraft's performance to the next level.",
  "/wolf"
);


export default [
  wolfPro360,
  wolfPitts,
  kitsPartsAndMore
];

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-index" },
    [
      _c("hero-canvas", {
        attrs: {
          lineTop: "TAKE PRIDE IN",
          lineBottom: "YOUR AIRCRAFT",
          "url-video": "/vid/loop.mov"
        }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "landingpageintro wrap grid32" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landingpageintro__right" },
          [
            _c("router-link", { attrs: { to: "" } }, [
              _c("button", { staticClass: "cta cta-body" }, [_vm._v("Contact")])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("section", { staticClass: "landingpageservices wrap" }, [
        _c("h2", { staticClass: "heading--jumbo" }, [_vm._v("Our services")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "landingpageservices__services grid-blurb" },
          _vm._l(_vm.servicesBlurbs, function(i, j) {
            return _c("services-blurb", {
              key: j,
              attrs: { heading: i.heading, body: i.body, url: i.url }
            })
          }),
          1
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("gallery-parallax"),
      _vm._v(" "),
      _c("callout", {
        attrs: {
          superheading: _vm.wolfSuperHeading,
          heading: _vm.wolfHeading,
          justify: "right"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid-blurb wrap" },
        _vm._l(_vm.wolfBlurbs, function(i, j) {
          return _c("services-blurb", {
            key: j,
            attrs: { heading: i.heading, body: i.body, url: i.url }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("flag", {
        staticClass: "flag-class",
        attrs: {
          text: "LEARN MORE",
          "font-size": "12",
          amplitude: 10,
          width: 140,
          height: 32,
          inset: 0.14,
          color: "white",
          hoverFill: "#ef2b38"
        }
      }),
      _vm._v(" "),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "landingpageintro__left" }, [
      _c("h1", { staticClass: "heading--body" }, [
        _vm._v(
          "\n        Griggs Aircraft is a refinishing, fabrication, and maintenance shop, and the proud owner of both the legendary Wolf line of aircraft and the O&N Silver Eagle conversion kits\n      "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
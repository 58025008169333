<template>
  <svg
    xmlns="www.w3.org/2000/svg"
    class="navicon"
    :width="width"
    :height="height">
    <rect
      :class="classNaviconBarTop"
      :style="baseBarStyle"
      x="0"
      y="8"
      width="100%"
      height="2"
      :fill="color">
    </rect>
    <rect
      :class="classNaviconBarBottom"
      :style="baseBarStyle"
      x="0"
      y="16"
      width="100%"
      height="2"
      :fill="color">
    </rect>
  </svg>
</template>


<script>


export default {
  name: "IconNav",
  props: {
    width: {
      type: String,
      required: false,
      default: "34"
    },
    height: {
      type: String,
      required: false,
      default: "26"
    },
    color: {
      type: String,
      required: false,
      default: "#ffffff"
    },
    duration: {
      type: String,
      required: false,
      default: "250ms"
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    makeClass (bar) {
      return {
        [`navicon__bar-${bar}--open`]: this.isOpen ? true : false
      }
    }
  },
  computed: {
    classNaviconBarTop () {
      return this.makeClass("top");
    },
    classNaviconBarBottom () {
      return this.makeClass("bottom");
    },
    baseBarStyle () {
      return {
        "transform-origin": "center",
        "transition": `y ${this.duration} ease, transform ${this.duration} ease`
      }
    }
  }
}
</script>


<style lang="sass">

.navicon
  cursor: pointer

@mixin animateBar($rot)
  y: 12
  transform: rotate($rot)

.navicon__bar-top--open
  @include animateBar(45deg)

.navicon__bar-bottom--open
  @include animateBar(-45deg)
  
</style>

<template>
	<svg 
    ref="scribble1svg"
    :width="width"
    viewBox="0 0 1574 196" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <g transform="matrix(1,0,0,1,-417.039,-2902.42)">
        <g transform="matrix(1,0,0,1,0,-6596.11)">
            <path 
              :class="scribbleClass"
              d="M418.289,9606.04C418.289,9606.04 1026.28,9401.15 1738.88,9560.4C2451.49,9719.65 1458.99,9537.43 1020.26,9606.04C777.745,9643.97 1294.82,9587.93 1456.35,9692.44"
              style="fill:none;stroke:url(#_Linear1);stroke-width:2.5px;"/>
          </g>
      </g>
      <defs>
        <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1686.15,0,0,133.777,310.453,16570.8)"><stop offset="0" style="stop-color:rgb(255,22,25);stop-opacity:1"/><stop offset="0.5" style="stop-color:rgb(255,22,25);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(236,111,31);stop-opacity:1"/></linearGradient>
    </defs>
  </svg>
</template>

<script>

export default {
  name: "GraphicScribble1",
  data () {
    return {
      isIntersecting: false
    }
  },
  props: {
    width: {
      type: String,
      required: false,
      default: "100%"
    }
  },
  computed: {
    scribbleClass () {
      return {
        "scrrrrribbblee": true,
        "animationclass": this.isIntersecting
      };
    }
  },
  mounted () {

    const opt = {
      root: null,
      rootMargin: "-20% 0%"
    };

    const ioHandler = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isIntersecting = true;
        }
      });
    };

    const io = new IntersectionObserver(ioHandler, opt);

    io.observe(this.$refs.scribble1svg);

  }
};
</script>

<style lang="sass">

@keyframes animscrib
  0%
    stroke-dashoffset: 3141
  100%
    stroke-dashoffset: 0

.scrrrrribbblee
  stroke-dashoffset: 3141
  stroke-dasharray: 3141 3141

.animationclass
  animation: animscrib 0.75s cubic-bezier(.53,.31,.25,.87) forwards
</style>

<template>
  <header class="global-header wrap">
    <router-link to="/">
      <icon-logo width="75"></icon-logo>
    </router-link>
    <desktop-dropdown></desktop-dropdown>
    <button class="global-header__contact-button">Contact</button>
    <button @click="openNav" class="global-header__navicon">
      <icon-nav
        :isOpen="isOpen">
      </icon-nav>
    </button>
  </header>
</template>

<script>
import IconLogo from "@/component/icon/logo/Index.vue";
import IconNav from "@/component/icon/nav/Index.vue";
import DesktopDropdown from "@/component/desktop-dropdown/Index.vue";


export default {
  name: "GlobalHeader",
  components: {
    IconLogo,
    IconNav,
    DesktopDropdown
  },
  methods: {
    openNav () {
      this.$modal.toggle();
    }
  },
  computed: {
    isOpen () {
      return this.$store.state.modal.isOpen;
    }
  }
};
</script>

<style lang="sass">
@import "~SASS/mixin.sass";
@import "~SASS/base/color";


.global-header
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  padding-top: 1.8rem
  z-index: 11

  &__navicon
    position: relative

  &__contact-button
    display: none
    color: white
    border: 1px solid white
    padding: 0 1rem
    padding: 0.3rem 1rem 0.2rem 1rem
    &:hover
      color

  @media screen and (min-width: 800px)
    &__navicon
      display: none
    &__contact-button
      display: block
</style>

<template>

<svg :width="width" viewBox="0 0 154 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-90.169,-176.849)">
        <g transform="matrix(0.697766,0,0,0.697766,90.169,40.085)" :fill="color">
            <path d="M68.21,293.373C68.21,293.373 71.628,288.494 73.842,285.334C75.065,283.589 76.022,281.671 76.682,279.645C77.705,276.506 79.148,272.075 79.148,272.075C73.729,266.838 69.643,260.233 67.448,252.819L34.511,252.819C31.675,252.819 28.956,251.693 26.951,249.688C24.945,247.682 23.819,244.963 23.819,242.127L23.819,242.126L75.686,242.126C76.07,249.247 78.633,255.792 82.721,261.108L100.222,207.387C87.295,211.188 77.533,222.417 75.883,236.126L65.826,236.126C66.9,224.55 72.439,214.261 80.702,207.002L10,207.002C7.348,207.002 4.804,205.948 2.929,204.073C1.054,202.198 -0,199.654 -0,197.002L-0,196.002L220,196.002C220,196.002 220,196.238 220,196.64C220,199.388 218.908,202.024 216.965,203.967C215.022,205.91 212.386,207.002 209.638,207.002L139.075,207.002C147.338,214.261 152.878,224.55 153.952,236.126L143.895,236.126C142.25,222.456 132.539,211.252 119.665,207.42L137.127,261.017C139.026,258.53 140.593,255.775 141.761,252.819L138.33,252.819L135.103,242.126L195.959,242.126C195.959,242.126 195.959,242.127 195.959,242.127C195.959,244.963 194.832,247.682 192.827,249.688C190.822,251.693 188.103,252.819 185.267,252.819L152.33,252.819C150.145,260.199 146.087,266.777 140.705,272.002C140.705,272.002 142.167,276.49 143.198,279.652C143.856,281.674 144.811,283.587 146.031,285.328C148.245,288.488 151.668,293.373 151.668,293.373L139.15,293.373C139.15,293.373 137.832,291.225 136.662,289.318C135.483,287.398 134.535,285.346 133.837,283.203C133.113,280.981 132.28,278.425 132.28,278.425C125.709,282.291 118.056,284.508 109.889,284.508C101.755,284.508 94.131,282.309 87.581,278.474C87.581,278.474 86.758,280.999 86.04,283.203C85.342,285.346 84.394,287.398 83.216,289.318C82.045,291.225 80.727,293.373 80.727,293.373L68.21,293.373ZM129.086,268.62C123.609,272.336 117.001,274.508 109.889,274.508C102.812,274.508 96.234,272.357 90.773,268.675L95.939,252.82L123.939,252.819L129.086,268.62ZM99.422,242.127L120.455,242.127L109.939,209.846L99.422,242.127Z"/>
        </g>
    </g>
</svg>

</template>

<script>


export default {
  name: "IconLogo",
  props: {
    width: {
      type: String,
      required: false,
      default: 100
    },
    color: {
      type: String,
      required: false,
      default: "#ffffff"
    }
  }
};
</script>

<style lang="sass">
</style>

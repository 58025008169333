<template>
  <div class="global-header-dd">
    <button class="global-header-dd__selection-button">Services</button>
    <button class="global-header-dd__selection-button">Fabrication</button>
    <button class="global-header-dd__selection-button">Wolf Products</button>
    <button class="global-header-dd__selection-button">Silver Eagle</button>
    <button class="global-header-dd__selection-button">Company</button>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="sass">
@import "~SASS/base/color";


.global-header-dd
  display: none
  @media screen and (min-width: 800px)
    display: block
  &__selection-button
    color: white
    margin-right: 1rem
    transition: margin-right 0.1s, color 0.15s
    &:hover
      color: var(--color-griggs-red)
    &:last-of-type
      margin-right: 0
    @media screen and (min-width: 900px)
      margin-right: 2rem

</style>


var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2"
      },
      attrs: {
        width: "100%",
        height: "100%",
        viewBox: "0 0 20 20",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "xml:space": "preserve",
        "xmlns:serif": "http://www.serif.com/"
      }
    },
    [
      _c("g", { attrs: { transform: "matrix(1,0,0,1,-742.902,-3021.1)" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "icon-link-out",
              transform: "matrix(1.01668,0,0,1,56.8602,-870.141)"
            }
          },
          [
            _c("path", {
              staticStyle: { fill: "rgb(239,43,56)" },
              attrs: {
                d:
                  "M685.592,3896.23L674.788,3896.23L674.788,3891.24L694.108,3891.24L694.108,3910.57L689.12,3910.57L689.12,3899.76L678.316,3910.57L674.788,3910.57L674.788,3907.04L685.592,3896.23Z"
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
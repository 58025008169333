var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "global-header wrap" },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [_c("icon-logo", { attrs: { width: "75" } })],
        1
      ),
      _vm._v(" "),
      _c("desktop-dropdown"),
      _vm._v(" "),
      _c("button", { staticClass: "global-header__contact-button" }, [
        _vm._v("Contact")
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "global-header__navicon", on: { click: _vm.openNav } },
        [_c("icon-nav", { attrs: { isOpen: _vm.isOpen } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
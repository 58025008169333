

export const createHiDpiCanvas = (w, h, ratio) => {
  if (!ratio) {
    throw "Please provide a pixel ratio to createHiDpiCanvas method.";
  }
  const canvas = document.createElement("canvas");
  canvas.width = w * ratio;
  canvas.height = h * ratio;
  canvas.style.width = w + "px";
  canvas.style.height = h + "px";
  canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
  return canvas;
};

export const fitCanvasTo = (canvas, target, ratio) => {
  if (!ratio) {
    throw "Please provide a pixel ratio to createHiDpiCanvas method.";
  }
  const w = target.offsetWidth;
  const h = target.offsetHeight;
  canvas.width = w * ratio;
  canvas.height = h * ratio;
  canvas.style.width = w + "px";
  canvas.style.height = h + "px";
  canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
};

export const getPixelRatio = () => {
  const ctx = document.createElement("canvas").getContext("2d");
  const dpr = window.devicePixelRatio || 1;
  const bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;
  return dpr / bsr;
};

export const getAspectRatio = (elem) => {
  const dims = getDimensions(elem);
  return (dims.width / dims.height);
};

export const getDimensions = (el) => {
  if (el.nodeName === "IMG") {
    return {
      width : el.naturalWidth,
      height : el.naturalHeight
    };
  } 
  if (el.nodeName === "CANVAS") {
    return {
      width : el.width,
      height : el.height
    };
  }
  if (el.nodeName === "VIDEO") {
    return {
      width: el.videoWidth,
      height: el.videoHeight
    };
  }
};

// Should probably create a function like "getCoverFigures" that just spits out
// the raw data to put into the "drawCover" function, so that I can get
// those values in order to put them into a "zoom" function, so it doesn't
// draw the cover twice

export const getCoverValues = (canvas, img, pixelRatio) => {
  const ctx = canvas.getContext("2d");
  const canvasDims = {};
  canvasDims.width = getDimensions(canvas).width / pixelRatio;
  canvasDims.height = getDimensions(canvas).height / pixelRatio;
  const imgDims = getDimensions(img);
  const aspectCanvas = getAspectRatio(canvas);
  const aspectImage = getAspectRatio(img);

  if (aspectImage > aspectCanvas) {
    const scaledWidth = (imgDims.width * (canvasDims.height / imgDims.height));
    const offset = (canvasDims.width - scaledWidth) / 2;
    return [img, offset, 0, scaledWidth, canvasDims.height];
  } else if (aspectImage < aspectCanvas) {
    const scaledHeight = (imgDims.height * ( canvasDims.width / imgDims.width));
    const offset = (canvasDims.height - scaledHeight) / 2;
    return [img, 0, offset, canvasDims.width, scaledHeight];
  } else {
    return [img, 0, 0, canvasDims.width, canvasDims.height];
  }
};

export const drawCover = (canvas, img, dx, dy, width, height) => {
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, dx, dy, width, height);
};

export const drawCoverZoom = (canvas, img, dx, dy, w, h, p) => {
  const ctx = canvas.getContext("2d");
  const newW = w*p;
  const newH = h*p;
  const newDx = dx + (w - newW) / 2;
  const newDy = dy + (h - newH) / 2;
  ctx.drawImage(img, newDx, newDy, newW, newH);
};

export const drawCoverOld = (canvas, img, pixelRatio) => {
  const ctx = canvas.getContext("2d");
  const canvasDims = {};
  canvasDims.width = getDimensions(canvas).width / pixelRatio;
  canvasDims.height = getDimensions(canvas).height / pixelRatio;
  const imgDims = getDimensions(img);
  const aspectCanvas = getAspectRatio(canvas);
  const aspectImage = getAspectRatio(img);

  if (aspectImage > aspectCanvas) {
    const scaledWidth = (imgDims.width * (canvasDims.height / imgDims.height));
    const offset      = (canvasDims.width - scaledWidth) / 2;
    ctx.drawImage(img, offset, 0, scaledWidth, canvasDims.height);
  } else if (aspectImage < aspectCanvas) {
    const scaledHeight = (imgDims.height * ( canvasDims.width / imgDims.width));
    const offset       = (canvasDims.height - scaledHeight) / 2;
    ctx.drawImage(img, 0, offset, canvasDims.width, scaledHeight);
  } else {
    ctx.drawImage(img, 0, 0, canvasDims.width, canvasDims.height);
  }
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-flag",
      attrs: {
        width: String(_vm.width),
        viewBox: "0 0 " + _vm.width + " " + _vm.computedSvgHeight
      },
      on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
    },
    [
      _c("defs", [
        _c("path", {
          staticClass: "icon-flag-text-path",
          attrs: { id: "flagIconTextPath", stroke: "none" }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        staticClass: "flag-outline-path",
        style: _vm.styleFlagHover,
        attrs: {
          stroke: "none",
          "stroke-width": _vm.strokeWidth,
          "stroke-linecap": "butt"
        }
      }),
      _vm._v(" "),
      _c("text", [
        _c(
          "textPath",
          {
            style: _vm.styleText,
            attrs: { href: "#flagIconTextPath", fill: _vm.textColor }
          },
          [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-header-dd" }, [
      _c("button", { staticClass: "global-header-dd__selection-button" }, [
        _vm._v("Services")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "global-header-dd__selection-button" }, [
        _vm._v("Fabrication")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "global-header-dd__selection-button" }, [
        _vm._v("Wolf Products")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "global-header-dd__selection-button" }, [
        _vm._v("Silver Eagle")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "global-header-dd__selection-button" }, [
        _vm._v("Company")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="grid32">
    <img src="">
    <img src="">
    <img src="">
    <img src="">
  </div>
</template>

<script>


export default {
  name: "GalleryParallax"
};
</script>

<style lang="sass">
</style>

<template>
	<svg 
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    class="icon-flag"
    :width="String(width)"
    :viewBox="`0 0 ${width} ${computedSvgHeight}` ">
		<defs>
      <path
        id="flagIconTextPath"
        class="icon-flag-text-path"
        stroke="none"/>
		</defs>
		<path 
      class="flag-outline-path"
      stroke="none"
      :stroke-width="strokeWidth"
      stroke-linecap="butt"
      :style="styleFlagHover"/>
		<text>
			<textPath
        href="#flagIconTextPath"
        :style="styleText"
        :fill="textColor">
        {{ text }}
      </textPath>
		</text>
	</svg>
</template>

<script>
import { range } from "@/util/array-utils.js";



// This value was taken from the original code I modified
// It's a mess. If there are bugs here in the future, this is probably why:

// 1) `time` seems to be an arbitrary value incremented on `requestAnimationFrame` loops
// 2) A different refresh herz could probably donk things up (though it works on this computer)
// 3) It should be converted into a frequency value, like 2 oscillations per second 
// 4) Changing all of this will be a PITA, and (though I'm sure it's not hard), I don't have time
//    to sort out how to calculate milliseconds from `requestAnimationFrame` or reorganize all
//    this code around a period setting

// So for right now, as it works, I'll leave it.
let time = 0;


export default {
  name: "IconFlag",
  props: {
    text: {
      type: String,
      required: false,
      default: "Text goes here"
    },
    color: {
      type: String,
      required: false,
      default: "white"
    },
    fontSize: {
      type: String,
      required: false,
      default: "auto"
    },
    hoverFill: {
      type: String,
      required: false,
      default: "lime"
    },
    textColor: {
      type: String,
      required: false,
      default: "#000000"
    },
    width: {
      type: Number,
      required: false,
      default: 275 
    },
    strokeWidth: {
      type: Number,
      required: false,
      default: 2
    },
    frequency: {
      type: Number,
      required: false,
      default: 30
    },
    amplitude: {
      type: Number,
      required: false,
      default: 10
    },
    height: {
      type: Number,
      required: false,
      default: 40 
    },
    wedgeSize: {
      type: Number,
      required: false,
      default: 0.075
    },
    inset: {
      type: Number,
      required: false,
      default: 0.1
    }
  },
  data () {
    return {
      hover: false
    }
  },
  computed:  {
    insetAsPixels () {
      return parseInt(this.inset * this.width);
    },
    computedSvgHeight () {
      return this.height + this.amplitude * 2;
    },
    styleFlagHover () {
      return this.hover ? { fill: this.hoverFill } : { fill: this.color, transition: "fill 300ms" };
    },
    styleText () {
      return { "font-size": this.fontSize };
    }
  },
  methods: {
    mouseover () {
      this.hover = true;
    },
    mouseleave () {
      this.hover = false;
    },
    animate () {
      if (time < -375) { time = 0; }

      const xs = range(this.strokeWidth, this.width - this.strokeWidth);
      const wedgePixels = parseInt(this.wedgeSize * this.width);

      const coords = xs.map(x => {
        let y = Math.sin((x + time) / this.frequency) * this.amplitude + this.amplitude;
        return [x, y];
      });

      // topPoints and bottomPoints should be consolidated into one function
      const topPoints = (() => {
        const x = coords[coords.length-1][0];
        const y = coords[coords.length-wedgePixels][1];
        const wedgeNode = [x - wedgePixels, y + this.height/2];
        return coords.concat([wedgeNode]);
      })();

      const bottomPoints = coords.map(p => {
        return [p[0], p[1] + this.height];
      }).reverse().concat([topPoints[0]]);
 
      const fullPoints = topPoints.concat(bottomPoints);

      const fullPath = "M" + fullPoints.map(p => {
        return `${p[0]}, ${p[1]}`;
      }).join(" L");


      const textPath = "M" + coords.map((p, idx) => {
        const y = coords[(idx + this.insetAsPixels) % (coords.length-this.insetAsPixels)][1];
        return `${p[0] + this.insetAsPixels},${y + this.height/ 2 + 5}`;
      }).join(" L");
      
      document.querySelector(".flag-outline-path").setAttribute("d", fullPath);
      document.querySelector(".icon-flag-text-path").setAttribute("d", textPath);
      
      time -= 1;
      
      requestAnimationFrame(this.animate);
    }
  },
  mounted () {
    this.animate();
  }
};
</script>

<style lang="sass">

.icon-flag
  cursor: pointer

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: _vm.idName, staticClass: "wrap callout" },
    [
      _c("video", {
        ref: "thetestelement1",
        staticClass: "callouttestelement",
        attrs: {
          src: "/vid/wolf/bennet-spin.mp4",
          loop: "",
          autoplay: "",
          muted: ""
        },
        domProps: { muted: true }
      }),
      _vm._v(" "),
      _c("video", {
        ref: "thetestelement2",
        staticClass: "callouttestelement2",
        attrs: {
          src: "/vid/wolf/tucker-blue-angels.mp4",
          loop: "",
          autoplay: "",
          muted: ""
        },
        domProps: { muted: true }
      }),
      _vm._v(" "),
      _c("video", {
        ref: "thetestelement3",
        staticClass: "callouttestelement3",
        attrs: {
          src: "/vid/wolf/bennet-inside-pitts.mp4",
          loop: "",
          autoplay: "",
          muted: ""
        },
        domProps: { muted: true }
      }),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "heading--superheading", style: _vm.bodyStyle },
        [_vm._v("\n    " + _vm._s(_vm.superheading) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "h1",
        {
          ref: "randomHeading",
          staticClass: "heading--jumbo random-heading-style",
          style: _vm.headingStyle
        },
        [_vm._v("\n    " + _vm._s(_vm.heading) + "\n  ")]
      ),
      _vm._v(" "),
      _c("scribble-1", { staticClass: "scribble", attrs: { width: "80%" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
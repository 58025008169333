<template>
  <div class="services-blurb">
    <router-link :to="url">
      <div class="services-blurb__heading">
        <h3>{{ heading }}</h3>
        <icon-arrow-out 
          class="services-blurb__arrow"
          width="9">
        </icon-arrow-out>
      </div>
    </router-link>
    <router-link :to="url">
    <p class="services-blurb__body">{{ body }}</p>
    </router-link>
  </div>
</template>

<script>
import IconArrowOut from "@/component/icon/arrow-out/Index.vue";


const loremIpsum = "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney.";


export default {
  name: "ServicesBlurb",
  props: {
    heading: {
      type: String,
      required: false,
      default: "Title"
    },
    body: {
      type: String,
      required: false,
      default: loremIpsum
    },
    url: {
      type: String,
      required: false,
      default: "/"
    }
  },
  components: {
    IconArrowOut
  }
}
</script>

<style lang="sass">
@import "~SASS/mixin";
@import "~SASS/base/color";

.services-blurb
  &__heading
    display: flex
    justify-content: space-between
  &__body
  &__arrow
    transition: transform 0.12s cubic-bezier(.63,.06,.5,1.03)
  &:hover .services-blurb__arrow
      transform: translate(0.6em, -0.6em)


</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "services-blurb" },
    [
      _c("router-link", { attrs: { to: _vm.url } }, [
        _c(
          "div",
          { staticClass: "services-blurb__heading" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.heading))]),
            _vm._v(" "),
            _c("icon-arrow-out", {
              staticClass: "services-blurb__arrow",
              attrs: { width: "9" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("router-link", { attrs: { to: _vm.url } }, [
        _c("p", { staticClass: "services-blurb__body" }, [
          _vm._v(_vm._s(_vm.body))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
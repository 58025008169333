var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "navicon",
      attrs: {
        xmlns: "www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height
      }
    },
    [
      _c("rect", {
        class: _vm.classNaviconBarTop,
        style: _vm.baseBarStyle,
        attrs: { x: "0", y: "8", width: "100%", height: "2", fill: _vm.color }
      }),
      _vm._v(" "),
      _c("rect", {
        class: _vm.classNaviconBarBottom,
        style: _vm.baseBarStyle,
        attrs: { x: "0", y: "16", width: "100%", height: "2", fill: _vm.color }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }